/*
 * @Auth: linjituan
 * @Date: 2021-07-21 16:15:21
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-10-27 22:39:30
 */
// eslint-disable-next-line
import { UserLayout } from '@/layouts'

/**
 * 首页
 */
export const indexRouterMap = [
  {
    path: '/index',
    name: 'index',
    component: 'Index',
    meta: { title: '首页', keepAlive: true, icon: 'dashboard' }
  },
  {
    path: '/account/center',
    name: 'center',
    component: 'AccountCenter',
    meta: { title: '个人中心', keepAlive: true },
    hidden: true
  },
  {
    path: '/account/settings',
    name: 'settings',
    component: 'AccountSettings',
    meta: { title: '个人设置', hideHeader: true },
    redirect: '/account/settings/base',
    hidden: true,
    children: [
      {
        path: '/account/settings/base',
        name: 'BaseSettings',
        component: 'BaseSettings',
        hidden: true,
        meta: { title: '基本设置', hidden: true }
      },
      {
        path: '/account/settings/security',
        name: 'SecuritySettings',
        component: 'SecuritySettings',
        meta: { title: '安全设置', hidden: true, keepAlive: true }
      }
    ]
  },
  {
    path: '/monitor/job/log',
    name: 'JobLog',
    component: 'JobLog',
    meta: { title: '调度日志', keepAlive: true },
    hidden: true
  },
  {
    path: '/system/notice/form',
    name: 'NoticeForm',
    component: 'NoticeForm',
    meta: { title: '公告编辑', keepAlive: true },
    hidden: true
  },
  {
    path: '/gen/edit',
    name: 'GenEdit',
    component: 'GenEdit',
    meta: { title: '修改生成配置', keepAlive: true },
    hidden: true
  },
  {
    path: '/bpbm',
    name: 'bpnm',
    component: 'bpnm',
    meta: { title: '流程图绘制', keepAlive: true },
    hidden: true
  },
  {
    path: '/jobRate',
    name: 'JobRate',
    component: 'JobRate',
    meta: { title: '作业完成率', keepAlive: true },
    hidden: true
  },
  {
    path: '/menuPage',
    name: 'MenuPage',
    component: 'menuPage',
    meta: { title: '', keepAlive: true },
    hidden: true
  },
  {
    path: '/monitor/suddenStopLog',
    name: 'SuddenStopLog',
    component: 'SuddenStopLog',
    meta: { title: '急停记录', keepAlive: true },
    hidden: true
  },
  {
    path: '/monitor/vehicleImportantEvents',
    name: 'VehicleImportantEvents',
    component: 'VehicleImportantEvents',
    meta: { title: '车辆事件记录', keepAlive: true },
    hidden: true
  },
  {
    path: '/iot/vehicleWarn',
    name: 'VehicleWarn',
    component: 'vehicleWarn',
    meta: { title: '车辆事件记录', keepAlive: true },
    hidden: true
  },
  {
    path: '/recyclable/sysSetting',
    name: 'sysSetting',
    component: 'sysSetting',
    meta: { title: '系统参数设置', keepAlive: true },
    hidden: true
  },
  {
    path: '/tenant/clientResource',
    name: 'clientResource',
    component: 'clientResource',
    meta: { title: 'Client资源配置' },
    hidden: true
  },
  {
    path: '/ota/info',
    name: 'otaTable',
    component: 'otaTable',
    meta: { title: 'ota管理' },
    hidden: true
  },
  {
    path: '/ota/config',
    name: 'otaConfig',
    component: 'otaConfig',
    meta: { title: 'ota配置' },
    hidden: true
  },
  {
    path: '/baiduRtcTest',
    name: 'baiduRtcTest',
    component: 'baiduRtcTest',
    meta: { title: '百度RTC测试' },
    hidden: true
  },
  {
    path: '/ota/info',
    name: 'otaTable',
    component: 'otaTable',
    meta: { title: 'ota管理' },
    hidden: true
  },
  {
    path: '/ota/config',
    name: 'otaConfig',
    component: 'otaConfig',
    meta: { title: 'ota配置' },
    hidden: true
  },
  {
    path: '/lightVirtual',
    name: 'otaConfig',
    component: 'lightVirtual',
    meta: { title: '大屏' },
    hidden: true
  }

  // {
  //   path: '/gpt/document',
  //   name: 'Document',
  //   component: 'gptDocument',
  //   meta: { title: '语料文档管理' }
  // },
  // {
  //   path: '/gpt/user',
  //   name: 'User',
  //   component: 'gptUser',
  //   meta: { title: '用户管理' }
  // },
  // {
  //   path: '/gpt/role',
  //   name: 'Role',
  //   component: 'gptRole',
  //   meta: { title: '角色权限管理' }
  // }
]
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      }
    ]
  },
  {
    path: '/openapi',
    component: UserLayout,
    redirect: '/openapi/authRedirect',
    hidden: true,
    children: [
      {
        path: 'authRedirect',
        name: 'authRedirect',
        component: () => import(/* webpackChunkName: "openapi" */ '@/views/openapi/authRedirect')
      },
      {
        path: 'authToGpt',
        name: 'authToGpt',
        component: () => import(/* webpackChunkName: "openapi" */ '@/views/openapi/authToGpt')
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  },
  {
    path: '/monitor/rtcScreen',
    name: 'RtcScreen',
    // component: 'rtcScreen',
    component: () =>
      import(/* webpackChunkName: "fail" */ '@/views/monitor/monitorForm/vehicle/funcList/modules/rtcScreen'),
    meta: { title: '摄像头' },
    hidden: true
  }
]
