import { Modal } from 'ant-design-vue'
export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    (event) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

// 把一個数组按照一定長度分割成若干数组
export function groupArray(array, subGroupLength) {
  let index = 0
  const newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)))
  }
  return newArray
}

// 给数组添加不重复的元素
export function pushUnique(array, el) {
  return Array.from(new Set(array).add(el))
  // if (!array) return array
  // if (array.length === 0) {
  //   array.push(el)
  // } else {
  //   const newArray = []
  //   // eslint-disable-next-line no-inner-declarations
  //   for (var i = 0; i < array.length; i++) {
  //     if (array.indexOf(el) === -1) {
  //       newArray.push(el)
  //     }
  //   }
  //   if (newArray.length > 0) {
  //     array = array.concat(newArray)
  //   }
  // }
  // return array
}

// 判断a、b两个数组是否相同
export function isEqualArray(a, b) {
  // 判断数组的长度
  if (a.length !== b.length) {
    return false
  } else {
    // 循环遍历数组的值进行比较
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false
      }
    }
    return true
  }
}

export function uuid() {
  const tempUrl = URL.createObjectURL(new Blob())
  var uuid = tempUrl.toString()
  return uuid.substr(uuid.lastIndexOf('/') + 1).replace(/-/g, '')
}
// 重新设置图片文件名
export function resetImageFileName(form, photoFieldName) {
  const imageUrl = form[photoFieldName]
  if (imageUrl) {
    const pictureIndex = imageUrl.indexOf('picture')
    const imgNameEndIndex = imageUrl.indexOf('?')
    const fileName = imageUrl.substring(pictureIndex + 8, imgNameEndIndex)
    console.log('fileName', fileName)
    form[photoFieldName] = fileName
  }
}

export function transformIdentityCard(value) {
  // sex 2未知  0男  1女
  const obj = {}
  if (value.length === 15) {
    obj.birthday = `19${value.substr(6, 2)}-${value.substr(8, 2)}-${value.substr(10, 2)}`
    obj.sex = value.substr(14, 1) % 2 ? 0 : 1
  }
  if (value.length === 18) {
    obj.birthday = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`
    obj.sex = value.substr(16, 1) % 2 ? 0 : 1
  }
  return obj
}

// 获取当前租户代码
export function getTenantCode() {
  const code = window.location.hostname.split('.')[0]
  return code
}

// 格式化秒为时间
export function huorsFormat(value) {
  let theTime = parseInt(value) // 秒
  let theTime1 = 0 // 分
  let theTime2 = 0 // 小时

  if (theTime >= 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)

    if (theTime1 >= 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }

  let result = ('0' + parseInt(theTime)).slice(-2)
  result = ('0' + parseInt(theTime1)).slice(-2) + ':' + result
  result = ('0' + parseInt(theTime2)).slice(-2) + ':' + result
  return result
}
// 加载脚本
export function loadScript(url, callback) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = 'async'
  script.src = url
  document.body.appendChild(script)
  if (script.readyState) {
    // IE
    script.onreadystatechange = function () {
      if (script.readyState === 'complete' || script.readyState === 'loaded') {
        script.onreadystatechange = null
        callback && callback()
      }
    }
  } else {
    // 非IE
    script.onload = function () {
      callback && callback()
    }
  }
}

// 确认对话框
export function MsgAnswer(content, times) {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: '确认',
      content: content,
      zIndex: 99999,
      onOk: () => {
        if (times === 2) {
          Modal.confirm({
            title: '二次确认',
            content: content,
            zIndex: 99999,
            onOk: () => {
              resolve()
            },
            onCancel() {}
          })
        } else {
          resolve()
        }
      },
      onCancel() {}
    })
  })
}

// 计算图表数字显示的Interval
export function getChartInterval(val) {
  if (val <= 1) {
    return 1
  }
  let interval = 0
  let baseVal = 1
  while (true) {
    if (val < baseVal) {
      interval = baseVal
      break
    } else {
      baseVal = baseVal * 10
    }
  }
  interval = Math.ceil(interval / 5)
  return interval < 1 ? 1 : interval
}
