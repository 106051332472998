import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { logWithTime } from '@/utils/ruoyi'
import { AddEndRecord } from '@/utils/delayTest'
// ws 数据缓存
const wsCache = {
  // 是否启用定时器接收处理WS数据功能
  isUsed: true,
  // 接收到数量
  receiveCount: 0,
  // 处理数量
  doneCount: 0,
  // 是否已处理
  isDone: true,
  // 定时器
  timer: null,
  // 增量更新列表
  partUpdateIds: [],
  // 最新ws数据
  data: {
    allInfo: null,
    partInfo: []
  },
  // ws数据处理函数
  fn: null,
  isPartUpdate: (type) => {
    if (
      type === 'tick' ||
      type === 'sweepingState' ||
      type === 'staticParams' ||
      type === 'runningState' ||
      type === 'location' ||
      type === 'lidar' ||
      type === 'ins' ||
      type === 'info' ||
      type === 'online' ||
      type === 'dynamicParams' ||
      type === 'processStatus' ||
      type === 'ctrlModeData' ||
      type === 'runEventData' ||
      type === 'workStatus' ||
      type === 'managedRequest' ||
      type === 'warnStatus' ||
      type === 'businessState'
    ) {
      return true
    } else {
      return false
    }
  },
  isAckData: (type) => {
    if (type === 'driveModeAck' || type === 'tosAuthAck' || type === 'motivationAck' || type === 'ack') {
      return true
    } else {
      return false
    }
  },
  updateData: (res) => {
    const data = res
    if (data.type === 'oneVehicle') {
      // 返回单辆车全量数据
      // 更新缓存某辆车数据
      // const tmpData = data.data
      // // 标识包含全量信息
      // tmpData.withAllInfo = true
      // wsCache.data.oneVehicle = tmpData
      // logWithTime(`返回单辆车全量数据,vehicleId=${tmpData.vehicleId}`)
    } else if (data.type === 'allVehicle') {
      // 全量数据更新
      // const list = data.data.list
      // const selectVehicle = data.data.select
      // for (let k = 0, n = list.length; k < n; k++) {
      //   if (list[k].vehicleId === selectVehicle.vehicleId) {
      //     list[k] = selectVehicle
      //     // 标识包含全量信息
      //     list[k].withAllInfo = true
      //     break
      //   }
      // }
      // wsCache.data.allInfo = {
      //   data: list
      // }
      // logWithTime('返回订阅车辆数据')
    } else if (data.type === 'all') {
      // 全量数据更新
      wsCache.data.allInfo = data
    } else if (wsCache.isPartUpdate(data.type)) {
      // 局部更新
      const obj = wsCache.data.partInfo.find((p) => p.vehicleId === data.vehicleId && p.type === data.type)
      if (!obj) {
        wsCache.data.partInfo.push(data)
      } else {
        if (data.time) {
          obj['time'] = data.time
        }
        obj[data.type] = data.data
      }
    } else if (wsCache.isAckData(data.type)) {
      // 更新ack数据
      wsCache.data.ackData = res
    } else if (data.type === 'planning') {
      // 更新planning数据
      wsCache.data.planningData = res
    }
  }
}
// websocket接收到的数据进行限流处理
const initWsTimer = () => {
  // 判断是否启用定时器处理WS功能
  if (!wsCache.isUsed) {
    return
  }
  wsCache.timer && clearInterval(wsCache.timer)
  wsCache.timer = setInterval(() => {
    if (wsCache.isDone === false) {
      wsCache.isDone = true
      try {
        wsCache.fn()
        ++wsCache.doneCount
        // console.log('接收到数量:' + wsCache.receiveCount + '，实际处理数量：' + wsCache.doneCount)
      } catch (e) {}
    }
  }, 0)
}
const websocket = {
  state: {
    websocketObj: {},
    websocketReady: false,
    websocketId: 0,
    allInfo: [],
    allInfoReady: false,
    ackData: {},
    sendString: '',
    planningAllInfo: [],
    isSelectVirtualVehicle: false,
    selectVirtualObj: {},
    trueVehicleData: {}
  },
  mutations: {
    setWebsocketReady: (state, data) => {
      state.websocketReady = data
    },
    setWebsocketObj: (state, data) => {
      state.websocketObj = data
    },
    addWebsocketId: (state) => {
      state.websocketId += 1
    },
    setAllInfoReady: (state, data) => {
      state.allInfoReady = data
    },
    setAllInfo: (state, data) => {
      state.allInfo = data.data
      state.planningAllInfo = data.data
      state.allInfoReady = true
    },
    // 更新一辆车
    setOneVehicle: (state, data) => {
      state.allInfo = state.allInfo.map((item) => {
        if (data.vehicleId === item.vehicleId) {
          return data
        }
        return item
      })
    },
    // 更新多辆车
    setManyVehicle: (state, data) => {
      state.allInfo = state.allInfo.map((item) => {
        const newItem = data.find((p) => p.vehicleId === item.vehicleId)
        if (newItem) {
          newItem.withAllInfo = true
          return newItem
        }
        return item
      })
    },
    setSendString: (state, data) => {
      state.sendString = data
    },
    updatePlaning: (state, data) => {
      state.planningAllInfo = state.allInfo.map((item) => {
        if (data.vehicleId === item.vehicleId) {
          console.log('item and data', item, data)
          item['planning'] = data.data
        }
        return item
      })
    },
    updateAllInfo: (state, data) => {
      // let isUpdate = false
      state.allInfo = state.allInfo.map((item) => {
        if (data.vehicleId === item.vehicleId) {
          // isUpdate = true
          if (data.time) {
            item['time'] = data.time
          }
          item[data.type] = data.data
        }
        return item
      })
      // if (!isUpdate) {
      //   // logWithTime(`车辆vehicleId=${data.vehicleId}增量更新失败`)
      //   // wsCache.partUpdateIds.push(data.vehicleId)
      // }
    },
    updateAckData: (state, data) => {
      console.log('ws更新ackData', data)
      AddEndRecord(data.type, data.timestamp, data.seq)
      if (data.type) {
        state.ackData[data.type] = data
      }
      if (data.type === 'login') {
        // 如果登录成功了，则要清除上一次的命令数据
        delete state.ackData.cmd
      }
      state.ackData = { ...state.ackData }
    },
    resetAckData: (state) => {
      state.ackData = {}
      console.log('resetAckData')
    },
    setVirtualState: (state, data) => {
      state.isSelectVirtualVehicle = data.isSelectVirtualVehicle
      state.selectVirtualObj = data.selectVirtualObj
      state.trueVehicleData = data.trueVehicleData
    }
  },
  actions: {
    websocketConnect({ commit, state, dispatch }) {
      const url = window.location.origin.replace('http', 'ws')
      const token = storage.get(ACCESS_TOKEN)
      let reconnect = false

      function connection() {
        let timer = null
        let interval = null
        if (!state.websocketReady) {
          return new Promise((resolve, reject) => {
            // const server = new WebSocket('ws://localhost:8443/ws?access_token=' + token)
            logWithTime('正在连接websocket')
            const server = new WebSocket(url + '/ws?access_token=' + token)
            server.onopen = () => {
              logWithTime('websocket 连接成功')
              // 初始化定时器用于处理WS数据接收
              initWsTimer()
              commit('setWebsocketReady', true)
              commit('setWebsocketObj', server)
              if (reconnect) {
                logWithTime('重新send一次')
                dispatch('websocketSubSend', { ids: state.sendString, type: 'void' })
                reconnect = false
              }
              interval = setInterval(() => {
                console.log('websocket 心跳')
                logWithTime('websocket 心跳')
                server.send(
                  '{"id":' + state.websocketId + ',"jsonrpc":"2.0","method":"ping","params":{"ping":"testping"}}'
                )
              }, 30000)
              resolve(server)
            }
            server.onclose = (event) => {
              commit('setWebsocketReady', false)
              commit('setWebsocketObj', {})
              wsCache.timer && clearInterval(wsCache.timer)
              reconnect = true
              // 连接错误，5s重新连接一次
              timer && clearTimeout(timer)
              interval && clearTimeout(interval)
              timer = setTimeout(() => {
                return connection()
              }, 5000)
              logWithTime(`websocket连接关闭,5秒后重连,error=${JSON.stringify(event)}`)
            }
            server.onerror = (event) => {
              commit('setWebsocketReady', false)
              commit('setWebsocketObj', {})
              reconnect = true
              // 连接错误，5s重新连接一次
              timer && clearTimeout(timer)
              interval && clearTimeout(interval)
              timer = setTimeout(() => {
                return connection()
              }, 5000)
              logWithTime(`websocket连接错误,5秒后重连,error=${JSON.stringify(event)}`)
              reject(event)
            }
          })
        }
      }
      return connection()
    },
    // module: subs 智驾监控订阅车辆，此时moduleData为空
    //         remotecontrol 遥控，此时moduleData为非空
    websocketSubSend(
      { dispatch, commit, state },
      { ids = '', type = '', str = '', selectId = '', payload = '', module = 'subs', moduleData = '' }
    ) {
      console.log('state.websocketReady', payload)
      if (payload) {
        // 检查当前车辆是否已包含全量数据
        if (payload.type === 'all') {
          // 多辆车
          commit('setAllInfoReady', false)
          const list = payload.data.list
          const selectVehicle = payload.data.select
          for (let k = 0, n = list.length; k < n; k++) {
            if (list[k].vehicleId === selectVehicle.vehicleId) {
              list[k] = selectVehicle
              // 标识包含全量信息
              list[k].withAllInfo = true
              break
            }
          }
          commit('setAllInfo', { data: list })
          wsCache.isDone = false
        } else if (payload.type === 'oneVehicle') {
          // 单辆车
          const data = payload.data
          data.withAllInfo = true
          commit('setOneVehicle', data)
          // 判断是否有增量数据待更新
          if (wsCache.data.partInfo.length > 0) {
            wsCache.isDone = false
          }
        } else if (payload.type === 'manyVehicle') {
          // 多辆车
          // 全辆更新与增量更新冲突呢
          const data = payload.data
          commit('setManyVehicle', data)
          // 判断是否有增量数据待更新
          if (wsCache.data.partInfo.length > 0) {
            wsCache.isDone = false
          }
        }
        // 判断是否需要订阅
        if (!payload.mustSubs) {
          return
        }
      }
      function handleData(res) {
        if (!res.jsonrpc) {
          if (res.type === 'all') {
            commit('setAllInfo', res)
            // } else if (res.type === 'info' || res.type === 'sensor' || res.type === 'chassis' || res.type === 'vehicle' || res.type === 'trailer' || res.type === 'locationError' || res.type === 'ads' || res.type === 'tos' || res.type === 'align' || res.type === 'error') {
          } else if (wsCache.isPartUpdate(res.type)) {
            // console.log('receive res:', res)
            commit('updateAllInfo', res)
          } else if (wsCache.isAckData(res.type)) {
            commit('updateAckData', res)
          } else if (res.type === 'planning') {
            commit('updatePlaning', res)
          }
        }
      }

      // 更新状态数据
      function updateState() {
        if (wsCache.data.allInfo) {
          commit('setAllInfo', wsCache.data.allInfo)
          wsCache.data.allInfo = null
        } else if (wsCache.data.oneVehicle) {
          // 更新指定车辆全量数据
          commit('setOneVehicle', wsCache.data.oneVehicle)
          wsCache.data.oneVehicle = null
          // 判断是否有增量数据待更新
          if (wsCache.data.partInfo.length > 0) {
            wsCache.isDone = false
          }
        } else if (wsCache.data.partInfo.length > 0) {
          // 用于记录是否已增量更新
          // wsCache.partUpdateIds = []
          wsCache.data.partInfo.forEach((p, idx) => {
            commit('updateAllInfo', p)
          })
          wsCache.data.partInfo.length = 0
        }
        if (wsCache.data.ackData) {
          commit('updateAckData', wsCache.data.ackData)
          wsCache.data.ackData = null
        }

        if (wsCache.data.planningData) {
          commit('updatePlaning', wsCache.data.planningData)
          wsCache.data.planningData = null
        }
      }

      if (!ids || !ids.length === 0) return
      let sendStr = ''
      // 订阅车辆
      if (module === 'subs') {
        console.log('发送订阅命令')
        sendStr =
          str ||
          `{"id": ${state.websocketId},"jsonrpc":"2.0","method":"subs","params":{"ids":${JSON.stringify(
            ids
          )}, "type":${JSON.stringify(type)}}}`

        if (type === 'all') {
          commit('setAllInfoReady', false)
        }
      } else if (module === 'remotecontrol' && moduleData && moduleData.cmd) {
        sendStr = `{"id": ${
          state.websocketId
        },"jsonrpc":"2.0","method":"vehicleNewControl","params":{"vehicleId":${JSON.stringify(
          ids[0]
        )}, "cmd":${JSON.stringify(moduleData.cmd)}}}`
        console.log('发送遥控命令', sendStr)
      }
      commit('setSendString', ids)

      if (state.websocketReady) {
        commit('addWebsocketId')
        // console.log('websocketSubSend sendStr', sendStr)
        logWithTime(`订阅=${sendStr}`)
        state.websocketObj.send(sendStr)
        state.websocketObj.onmessage = (event) => {
          try {
            logWithTime(`收到websocket数据=${event.data}`)
            const wsData = JSON.parse(event.data)
            if (wsData.type === 'ack') {
              // 如果是遥控数据，则立即执行
              commit('updateAckData', wsData.data)
              return
            }

            if (wsCache.isUsed) {
              // 启用定时器处理WS功能
              // websocket接收到的数据进行限流处理
              // 如果WS接收频率过度，接收处理这些数据都要消耗太多性能，导致页面卡死
              // 如果不启用定时器，容易出现页面突然卡的现象，然后导致页面数据更新延迟问题
              // logWithTime(`收到数据-` + event.data)
              wsCache.fn = updateState
              wsCache.updateData(wsData)
              wsCache.isDone = false
              ++wsCache.receiveCount
            } else {
              handleData(wsData)
            }
          } catch (e) {
            console.log('websocket error:', event.data)
          }
        }
      } else {
        dispatch('websocketConnect').then(() => {
          commit('addWebsocketId')
          state.websocketObj.send(sendStr)
          state.websocketObj.onmessage = (event) => {
            const res = JSON.parse(event.data)
            handleData(res)
          }
        })
      }
    },
    stopWebSocket({ commit, state }) {
      console.log('---stopWebSocket---')
      if (state.websocketObj && state.websocketObj.readyState === 1) {
        state.websocketObj && state.websocketObj.send(`{"id": ${state.websocketId},"jsonrpc":"2.0","method":"unSubs"}}`)
        logWithTime(`取消订阅,websocketId=${state.websocketId}`)
      }
    },
    // 退出遥控，重置上一次的ack数据
    resetAck({ commit, state }) {
      commit('resetAckData')
    },
    websocketUnSubSend({ commit, state }, { type = '' }) {
      console.log('---websocketUnSubSend---')
      state.websocketObj &&
        state.websocketObj.send(
          `{"id": ${state.websocketId},"jsonrpc":"2.0","method":"unSubs", "params":{"type":${JSON.stringify(type)}}}`
        )
    },
    setVirtual({ commit, state }, info) {
      commit('setVirtualState', info)
    }
  }
}

export default websocket
